.AppChat {
  text-align: center;
  display: flex;
  background-color: #282c34;
  color: white;
  position: absolute;
  top: 0;bottom: 0;right: 0;left: 0;
}

.sidemenu {
  width: 260px;
  padding: 10px;
  background-color: #202123;
}

.side-menu-logo {
  padding: 12px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
}

.side-menu-button {
  padding: 12px;
  border: 1px solid white;
  border-radius: 5px;
  cursor: pointer;
  transition: ease 0.25s all;
}

.side-menu-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.chatbox {
  position: relative;
  flex: 1;
  background-color: #343541;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.chat-input-holder {
  padding: 50px;
  position: absolute;
  bottom: 0;left: 0;right: 0;
}

.chat-input-textarea {
  background-color: #40414f;
  width: 100%;
  padding: 12px;
  color: white;
  font-size: 1.25em;
  border-radius: 5px;
  border: none;
  outline: none;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.chat-log {
  padding-top: 50px;
  text-align: left;
}

.chat-message.chatgpt {
  background-color: #444654;
}

.chat-message-center {
  display: flex;
  max-width: 640px;
  margin-left: 50px;
  padding: 12px 0 12px 0;
}

.avatar {
  background: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.avatar.chatgpt {
  background: #0da37f;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.message {
  padding: 0 40px 0 40px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
